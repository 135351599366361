<template>
  <div
    class="flex-col mt-2 mb-2 overflow-hidden hidden md:flex will-change-transform min-w-[767px] w-full lg:ps-28 md:ps-10 ps-4"
  >
    <div
      class="schedule-head flex gap-x-2 mb-6 max-w-full overflow-x-auto overflow-y-hidden scrollbar-hide md:pe-10 lg:pe-28"
    >
      <div
        v-show="isShowDate"
        v-for="i in 8"
        :key="i"
        @click="handleSelect(i - 1)"
        class="schedule-date flex-shrink-0"
        :class="[`schedule-date-${i}`]"
        v-focusable
      >
        <ButtonsCta
          label="Glass button"
          class="schedule-date-button variant-glass border-2 border-transparent"
          :class="{
            'btn-selected': currentDate === i - 1,
          }"
        >
          {{ todayAndTomorrow(i) }}
        </ButtonsCta>
      </div>
    </div>
    <div
      class="schedule-content relative flex max-w-full text-white overflow-x-auto overflow-y-hidden scrollbar-hide"
      ref="containerRef"
    >
      <div class="channel-list flex flex-col" :class="locale">
        <div
          class="flex justify-start flex-shrink-0 items-start w-[195px] h-12 text-white/40 text-start !bg-transparent !backdrop-blur-0"
        >
          {{ $t("Channel") }}
        </div>
        <!-- fixed-container -->
        <UiScheduleEpgCard
          v-for="(program, rowIdx) in formattedProgramList"
          :key="rowIdx"
          :channel="getChannel(program.channelExternalId)"
          class="schedule-program-name flex flex-row justify-center items-center flex-shrink-0 flex-grow-0 w-[195px] h-16 !bg-white/10 !backdrop-blur-0 rounded mb-1 z-10 last:mb-0"
        ></UiScheduleEpgCard>
      </div>
      <div
        class="schedule-scroll-view flex-1 overflow-y-auto overflow-y-hidden"
        ref="scrollViewRef"
      >
        <!-- 9560 = item width(195) * 48 + padding(4) * 50 -->
        <div class="w-fit box-content rounded relative me-4 md:me-10 lg:me-28">
          <div
            class="absolute top-0 flex flex-col items-center z-10"
            :style="
              locale == 'ar'
                ? { right: scrollToNowWidth + 'px' }
                : { left: scrollToNowWidth + 'px' }
            "
            v-show="currentDate === 0"
          >
            <Icon name="Iconschedule" size="16" class="rtl:-scale-x-100"></Icon>
            <div
              class="w-0.5 bg-brand-red mt-[-3px]"
              :style="{
                height: scrollHeight + 'px',
              }"
            ></div>
          </div>
          <div class="w-full flex mb-6">
            <div
              v-for="i in 48"
              :key="i"
              class="flex justify-start flex-shrink-0 items-center w-[195px] h-6 ps-2 border-l border-white/30"
            >
              {{ formateHour(i) }}
            </div>
          </div>
          <div
            v-for="(program, rowIdx) in formattedProgramList"
            :key="program.channelId"
            class="program-list relative flex items-center min-w-[9365px] mb-1"
          >
            <div v-for="(programItem, pIdx) in program.programs" :key="pIdx">
              <!-- right for language arabic -->
              <div
                v-if="isNeedShow(programItem.width)"
                @click="programClickHandle(program, programItem)"
                class="me-1 schedule-program-detail relative flex flex-col justify-center items-start flex-shrink-0 h-16 rounded bg-white/10 border-[2px] border-transparent"
                :class="[
                  `schedule-program-detail-${programItem.carType}`,
                  `schedule-program-detail-${programItem.id}`,
                  programItem.isHidPadding && 'padding-zero',
                ]"
                :style="programStyle(programItem)"
              >
                <div
                  class="w-full flex justify-between items-center h-6 flex-shrink-0"
                  v-if="!isSmall(programItem.width)"
                >
                  <div class="text-body-small truncate">
                    {{ startDateFormate(programItem.startDate) }}
                  </div>
                  <!-- <div>
                    <div
                      v-if="programItem.carType !== 'now'"
                      @click="moreHandle(programItem.id)"
                      class="
                        text-button-small
                        cursor-pointer
                        border border-white/10
                        bg-white/5
                        rounded-full
                        px-2
                        py-1
                      "
                    >
                      {{ $t("More") }}
                      <Icon name="IconArrow" width="16" height="16"></Icon>
                    </div>
                  </div> -->
                  <UiTagLive size="big" v-if="showLiveTag(programItem)" />
                </div>
                <div
                  class="text-button-medium truncate box-content w-full text-start"
                >
                  {{ programItem.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { DateTime } from "luxon";
import { useRadioStore } from "@/stores/radio";
import { useSessionStore } from "@/stores/session";

const { t, locale } = useI18n();
const route = useRoute();
const router = useRouter();
const sessionStore = useSessionStore();

const currentDate = ref(0);
const ONE_MINUTES_WIDTH = 6.5;
const ITEM_MIN_WIDTH = 130;
const ITEM_MARGIN = 4;
const TITLE_WIDTH = 195;
const scrollHeight = ref(0);
const containerRef = ref();
const scrollViewRef = ref();
const programList = ref([]);
const carType = ref(""); // pass now later
const scrollToNowWidth = ref();
const localePath = useLocalePath();
const formateHour = (i) => {
  return DateTime.fromObject({ hour: 0 })
    .plus({ minutes: 30 * (i - 1) })
    .toFormat("HH:mm");
};

const direction = computed(() => {
  return locale.value === "ar" ? -1 : 1;
});

const props = defineProps({
  channels: {
    type: Array,
    default: () => [],
  },
  kind: {
    type: String,
    defualt: "live", //live | sports | radio
  },
  isShowDate: {
    type: Boolean,
    default: true,
  },
});

const epgCardImg = computed(() => (id) => {
  const item = props.channels.find((it) => it.id == id);
  return item ? useMediaAssets(item) : {};
});
//get channel id
const channelIDs = computed(() => {
  const channels = props.channels.map((c) => c.externalChannelId).join(";");
  return encodeURIComponent(channels);
});

const programStyle = (programItem) => {
  const styleObj = {
    width: programItem.width,
  };
  if (locale.value === "ar") {
    styleObj.right = programItem.left;
  } else {
    styleObj.left = programItem.left;
  }
  return styleObj;
};

// startDate label start at midNight
const startDateFormate = (startDate) => {
  const midNightMills = getMidNightMills(currentDate.value);
  if (midNightMills - startDate > 0) {
    return DateTime.fromMillis(midNightMills).toFormat("HH:mm");
  } else {
    return DateTime.fromMillis(startDate).toFormat("HH:mm");
  }
};

const getChannel = (channelId) => {
  const decoded = decodeURI(channelId);
  return props.channels.find((c) => c.externalChannelId == decoded);
};

const formattedProgramList = computed(() => {
  const midNightMills = getMidNightMills(currentDate.value);
  const tomorrowMidNightMills = getMidNightMills(currentDate.value + 1);

  const res = programList.value.map((item) => {
    let startOffset = 0;
    let newList = [];

    const sortedList = item.programs.sort((o, n) => o.startDate - n.startDate);

    // add no data item.
    for (let index = 0; index < sortedList.length; index++) {
      const programItem = sortedList[index];

      const startTime = DateTime.fromMillis(programItem.startDate);
      const endTime = DateTime.fromMillis(programItem.endDate);
      const nowTime = DateTime.now().toMillis();

      let midNightDiff = "default";
      let LastItemMidNightDiff = "default";
      let width = 0;

      // calc left offset: firstItem is midnight(00:00) , left offset = item.startDate - prevItem.startDate
      if (index === 0) {
        midNightDiff = startTime - midNightMills;
        if (midNightDiff > 0) {
          startOffset =
            calcMinuteWidth(DateTime.fromMillis(midNightMills), startTime) +
            ITEM_MARGIN;
        }
        // last item: max time is midnight
      } else if (index === sortedList.length - 1) {
        LastItemMidNightDiff = endTime - tomorrowMidNightMills;
      }

      if (midNightDiff !== "default" && midNightDiff < 0) {
        width = calcMinuteWidth(DateTime.fromMillis(midNightMills), endTime);
      } else if (
        LastItemMidNightDiff !== "default" &&
        LastItemMidNightDiff > 0
      ) {
        width = calcMinuteWidth(
          startTime,
          DateTime.fromMillis(tomorrowMidNightMills)
        );
      } else {
        width = calcMinuteWidth(startTime, endTime);
      }

      carType.value = produceCarType(
        nowTime,
        programItem.startDate,
        programItem.endDate
      );

      if (index > 0) {
        const prevItem = sortedList[index - 1];
        let noDataWidth = calcMinuteWidth(
          DateTime.fromMillis(prevItem.endDate),
          startTime
        );

        if (noDataWidth > 0) {
          newList.push({
            ...prevItem,
            id: prevItem.id + "noData",
            name: t("NoData"),
            startDate: prevItem.endDate,
            isHidPadding: noDataWidth <= 29 ? true : false,
            width: noDataWidth + "px",
            carType: "pass",
            left: startOffset + "px",
            images: useMediaAssets(prevItem),
          });
        }
      }
      // remove padding
      newList.push({
        ...programItem,
        isHidPadding: width <= 29 ? true : false,
        width: width + "px",
        left: startOffset + "px",
        carType: carType.value,
        images: useMediaAssets(programItem),
      });
    }

    // append last item which is no data.
    if (newList.length > 0) {
      const nowTime = DateTime.now().toMillis();
      const lastItem = newList[newList.length - 1];
      const endTime = DateTime.fromMillis(lastItem.endDate);
      const appendTime = tomorrowMidNightMills - endTime;
      let appendItem = null;

      let width = calcMinuteWidth(
        endTime,
        DateTime.fromMillis(tomorrowMidNightMills)
      );

      if (appendTime > 0) {
        appendItem = {
          ...lastItem,
          startDate: lastItem.endDate,
          id: lastItem.id + "noData",
          name: t("NoData"),
          width: width + "px",
          carType: "pass",
          images: useMediaAssets(lastItem),
        };
        newList.push(appendItem);
      }
    }

    return {
      ...item,
      programs: newList,
    };
  });
  return res;
});

const isNeedShow = (widthString) => {
  const width = widthString.replace("px", "");
  if (!isNaN(width) && width > 0) {
    return true;
  } else {
    return false;
  }
};

const produceCarType = (nowTime, startTime, endTime) => {
  if (nowTime > startTime && nowTime < endTime) {
    return "now";
  } else if (startTime > nowTime) {
    return "later";
  } else {
    return "pass";
  }
};
const getProgramList = async () => {
  const midNightMills = getMidNightMills(currentDate.value);
  const tomorrow = getMidNightMills(currentDate.value + 1);
  const res = await useGetMethod("/api/biz/program/list", {
    channelIDs: channelIDs.value,
    startDate: midNightMills,
    endDate: tomorrow,
  });
  programList.value = res;
};

const handleSelect = async (selectedDate) => {
  currentDate.value = selectedDate;
  await getProgramList();
  currentDate.value === 0 && scrollToNow();
};
const getMidNightMills = (plusDayFromToday) => {
  return DateTime.fromISO(
    DateTime.now().plus({ days: plusDayFromToday }).toISODate()
  ).toMillis();
};
const calcMinuteWidth = (start, end) => {
  const diffInMinutes = end.diff(start, "minute");
  const minutes = diffInMinutes.toObject().minutes;
  const res =
    diffInMinutes.toObject().minutes * ONE_MINUTES_WIDTH - ITEM_MARGIN;
  return res;
};
const getProgressWidth = () => {
  const midNightMills = getMidNightMills(0);
  return calcMinuteWidth(DateTime.fromMillis(midNightMills), DateTime.now());
};

const isSmall = (width) => {
  const w = width?.replace("px", "");
  return w <= ITEM_MIN_WIDTH;
};

const scrollToNow = () => {
  nextTick(() => {
    scrollHeight.value = containerRef.value.scrollHeight - 20;
    // do not use scrollintoview: because position is sticky. Have error when you click another day
    const toScrollLeft =
      direction.value *
      (getProgressWidth() - containerRef.value.clientWidth / 2);
    if (locale.value === "ar") {
      scrollViewRef.value.scrollLeft = toScrollLeft - TITLE_WIDTH;
      scrollToNowWidth.value =
        Math.abs(toScrollLeft) +
        containerRef.value.clientWidth / 2 -
        ITEM_MARGIN * 3;
    } else {
      scrollViewRef.value.scrollLeft = toScrollLeft + TITLE_WIDTH;
      scrollToNowWidth.value =
        toScrollLeft + containerRef.value.clientWidth / 2 - ITEM_MARGIN * 3;
    }
  });
};

const moreHandle = (programId) => {
  //TODO: detail page info
  console.log("click more", programId);
};

const programClickHandle = async (program, programItem) => {
  if ((program.isPlus || programItem.isPlus) && !sessionStore.isAuthenticated) {
    useNeedLoginPopup().isUnAuthPopup();
    return true;
  }

  if (programItem?.id?.toString().indexOf("noData") >= 0) {
    return;
  }
  if (programItem.carType == "now") {
    const selectedChannel = getChannel(program.channelExternalId);

    if (selectedChannel.number > 100) {
      // is a radio station

      if (!useNeedLoginPopup().isPlusNeedPopup(selectedChannel)) {
        useRadioStore().$patch((state) => {
          state.widgetStation = selectedChannel;
          state.showWidget = true;
        });
      }
      return;
    }

    if (selectedChannel.externalChannelId === "Majid Children Channel") {
      navigateTo({
        path: localePath(`/kids/majid-tv`),
        query: {
          autoplay: true,
          channel: selectedChannel.externalChannelId,
        },
      });
    } else {
      navigateTo({
        path: localePath(`/live/${selectedChannel.externalChannelId}`),
        query: {
          autoplay: true,
        },
      });
    }
  }
};

onMounted(async () => {
  await getProgramList();
  scrollToNow();
});
const todayAndTomorrow = (i) => {
  if (i - 1 === 0) {
    return t("Today");
  } else if (i - 1 === 1) {
    return t("Tomorrow");
  } else {
    const now = DateTime.now().plus({ days: i - 1 });
    return `${now.toFormat("EEE")} ${now.toFormat("dd MMM")}`;
  }
};

/**
 * - the live tag logic in EPG is wrong, we should show it for
 * the current program only when its ef:telecast = live.
 * otherwise only highlight, no live tag.
 */
const showLiveTag = (programItem) => {
  const isNow = programItem.carType == "now";
  const isTelecastLive =
    programItem?.extrafields.find((e) => e.name == "telecast_type")?.value ==
    "Live";

  return isNow && isTelecastLive;
};
</script>
<style lang="scss" scoped>
.fixed-container {
  @apply md:start-10 lg:start-28 start-4 fixed;
}
.btn-selected {
  border: 2px solid
    var(--stroke-stroke-state-input-activated, rgba(255, 255, 255, 0.8));
}
.channel-list {
  box-shadow: 5px 5px 6px 3px rgba(0, 0, 0, 0.5);
  &.ar {
    box-shadow: 5px 5px 6px 16px rgba(0, 0, 0, 0.5);
  }
}

.schedule-program {
  &-detail {
    padding: 8px 16px;
    &-pass {
      background: var(--greys-white-10, rgba(255, 255, 255, 0.1));
      color: var(--greys-white-40, rgba(255, 255, 255, 0.4));
    }
    &-now {
      background: var(--greys-white-30, rgba(255, 255, 255, 0.3));
      cursor: pointer;
    }
    &-later {
      background: var(--greys-white-10, rgba(255, 255, 255, 0.1));
      cursor: pointer;
    }
  }
}
.padding-zero {
  padding: 0px;
}
</style>
