<template>
  <div
    class="flex items-center justify-center rounded"
    :class="[
      isXs ? 'px-[10px] py-4' : 'px-0 py-0',
      isSelected ? 'bg-white/30' : 'bg-white/10',
    ]"
  >
    <div
      class="w-full h-full bg-center bg-no-repeat bg-contain"
      :style="{ backgroundImage: `url('${parsedChannel.images?.logo}')` }"
    >
      <!-- <div class="absolute top-0 -end-1 rounded-full w-1 h-1 bg-red-600"></div> -->
    </div>
  </div>
</template>
<script setup>
const { isXs } = useBreakpoints();

const props = defineProps({
  channel: {
    type: Object,
    required: true,
  },
  epgCardImg: {
    type: String,
    required: true,
  },
  isSelected: {
    type: Boolean,
  },
});

const parsedChannel = computed(() => {
  if (props.channel && Object.keys(props.channel).includes("images")) {
    return props.channel;
  }

  return {
    ...props.channel,
    images: useMediaAssets(props.channel),
  };
});
</script>
<style lang="scss" scoped></style>
