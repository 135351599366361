<template>
  <div
    class="lg:pt-20 gradient radial"
    :class="[isRTL ? 'from-right' : 'from-left']"
  >
    <div class="flex items-center md:pt-6 pt-4 gap-x-4 mb-4 lg:ps-28 md:ps-10 ps-4">
      <ButtonsIcon
        v-if="showBack"
        iconName="IconBack"
        size="24"
        class="variant-glass rtl:rotate-180"
        @click="useBackEvent"
      >
      </ButtonsIcon>
      <div class="text-title-medium sm:text-title-big text-white">
        {{ $t(title) }}
      </div>
    </div>
    <client-only>
      <div class="w-full" v-if="isMdAndUp">
        <UiSchedule :channels="props.channels" :kind="props.kind" :isShowDate="isShowDate"></UiSchedule>
      </div>
      <div class="w-full overflow-x-auto" v-else>
        <UiScheduleSmSchedule
          :channels="props.channels"
          :kind="props.kind"
          :isShowDate="isShowDate"
        ></UiScheduleSmSchedule>
      </div>
    </client-only>
  </div>
</template>

<script setup>
const { isMdAndUp } = useBreakpoints();
const { isRTL } = useCurrentLocale();
const props = defineProps({
  channels: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    default: "Schedule",
  },
  showBack: {
    type: Boolean,
    default: () => true,
  },
  kind: {
    type: String,
    default: "live",
  },
  isShowDate: {
    type: String,
  },
});
</script>

<style lang="scss" scoped></style>
