<template>
  <div class="flex flex-col justify-center items-start overflow-x-hidden">
    <div
      class="flex justify-start items-center overflow-x-auto scrollbar-hide w-full gap-x-2 mt-3 mb-4 px-4"
    >
      <div
        v-show="props.isShowDate"
        v-for="i in 8"
        :key="i"
        @click="handleSelect(i - 1)"
        class="schedule-date flex-shrink-0"
        :class="[`schedule-date-${i}`]"
        v-focusable
      >
        <ButtonsCta
          label="Glass button"
          class="schedule-date-button variant-glass border-2 border-transparent"
          :class="{
            'btn-selected': currentDate === i - 1,
          }"
        >
          {{ todayAndTomorrow(i) }}
        </ButtonsCta>
      </div>
    </div>
    <div class="flex flex-col justify-center items-start w-full">
      <div class="flex gap-x-2 mb-4 overflow-x-auto scrollbar-hide w-full px-4">
        <div
          v-for="(program, rowIdx) in formattedProgramList"
          :key="program.channelId"
          class="program-list relative flex items-center justify-center flex-shrink-0"
        >
          <!-- fixed-container -->
          <div
            class="flex w-[88px] h-[56px]"
            @click="epgClickHandle(program)"
          >
            <UiScheduleEpgCard
              :channel="getChannel(program.channelExternalId)"
              :isSelected="program.channelId === currentPrograms?.channelId"
              class="schedule-program-name sticky start-0 flex flex-row justify-center items-center flex-shrink-0 flex-grow-0 bg-white/10 rounded z-10 shadow-lg w-full h-full !px-0 !py-0"
            ></UiScheduleEpgCard>
          </div>
        </div>
      </div>

      <div
        class="channel-list w-full overflow-y-auto flex flex-col gap-y-[10px] px-4"
        v-if="currentPrograms"
      >
        <div
          v-for="(programItem, pIdx) in currentPrograms.programs"
          :key="pIdx"
        >
          <div
            @click="programClickHandle(programItem)"
            class="schedule-program-detail text-white relative flex justify-between items-start h-16 rounded bg-white/10 border-[2px] border-transparent"
            :class="[
              `schedule-program-detail-${programItem.carType}`,
              `schedule-program-detail-${programItem.id}`,
            ]"
          >
            <div class="flex flex-col flex-1 w-0">
              <div class="w-full flex justify-start items-end">
                <div class="text-body-small truncate text-white/80">
                  {{ startDateFormate(programItem.startDate) }}
                </div>
              </div>
              <div class="text-button-medium truncate mt-1 w-full text-start">
                {{ programItem.name }}
              </div>
            </div>
            <div class="self-center">
              <!-- <div
                v-if="programItem.carType !== 'now'"
                @click="moreHandle(programItem.id)"
                class="text-button-small cursor-pointer border border-white/10 bg-white/5 rounded-full min-w-[65px] px-2 py-1 flex items-center justify-center"
              >
                {{ $t("More") }}
                <Icon
                  name="IconArrow"
                  width="16"
                  height="16"
                  class="rtl:-scale-x-100"
                ></Icon>
              </div> -->
              <UiTagLive
                size="big"
                v-if="showLiveTag(programItem)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRadioStore } from "@/stores/radio";
import { useSessionStore } from "@/stores/session";

import { DateTime } from "luxon";
const localePath = useLocalePath();
const router = useRouter();
const sessionStore = useSessionStore();
const { t } = useI18n();
const currentDate = ref(0);
const programList = ref([]);
const carType = ref(""); // pass now later
const currentPrograms = ref();
const currentProgramIdx = ref();

const props = defineProps({
  channels: {
    type: Array,
    default: () => [],
  },
  kind: {
    type: String,
    defualt: "live", //live | sports | radio
  },
  isShowDate: {
    type: Boolean,
    default: true,
  },
});
const getChannel = (channelId) => {
  const decoded = decodeURI(channelId);
  return props.channels.find((c) => c.externalChannelId == decoded);
};
//get channel id
const channelIDs = computed(() => {
  const channels = props.channels.map((c) => c.externalChannelId).join(";");

  return encodeURIComponent(channels);
});

const formattedProgramList = computed(() => {
  const midNightMills = getMidNightMills(currentDate.value);
  const res = programList.value.map((item) => {
    // on radio one station page only show next six channel.
    let nowIdx;
    let newChannels = item.programs
      .sort((o, n) => o.startDate - n.startDate)
      .filter((i) => {
        return i.endDate > midNightMills;
      })
      .map((programItem, idx) => {
        const nowTime = DateTime.now().toMillis();
        carType.value = produceCarType(
          nowTime,
          programItem.startDate,
          programItem.endDate
        );
        if (carType.value === "now") {
          currentProgramIdx.value = idx;
          nowIdx = idx;
        }

        return {
          ...programItem,
          carType: carType.value,
        };
      });

    if (!props.isShowDate) {
      newChannels = newChannels.splice(nowIdx, 6);
    }

    return {
      ...item,
      programs: newChannels,
    };
  });
  return res;
});

const scrollToView = () => {
  nextTick(() => {
    const nowDom = document.querySelector(".schedule-program-detail-now");
    nowDom?.scrollIntoView({ block: "center", inline: "start" });
  });
};

// startDate label start at midNight
const startDateFormate = (startDate) => {
  const midNightMills = getMidNightMills(currentDate.value);
  if (midNightMills - startDate > 0) {
    return DateTime.fromMillis(midNightMills).toFormat("HH:mm");
  } else {
    return DateTime.fromMillis(startDate).toFormat("HH:mm");
  }
};

const epgClickHandle = (program) => {
  currentPrograms.value = program;
  scrollToView();
};

const produceCarType = (nowTime, startTime, endTime) => {
  if (nowTime > startTime && nowTime < endTime) {
    return "now";
  } else if (startTime > nowTime) {
    return "later";
  } else {
    return "pass";
  }
};

const getProgramList = async () => {
  const midNightMills = getMidNightMills(currentDate.value);
  const tomorrow = getMidNightMills(currentDate.value + 1);
  const res = await useGetMethod("/api/biz/program/list", {
    channelIDs: channelIDs.value,
    startDate: midNightMills,
    endDate: tomorrow,
  });
  programList.value = res;
};

const handleSelect = async (selectedDate) => {
  currentDate.value = selectedDate;
  await getProgramList();
  currentPrograms.value = formattedProgramList.value?.[0];
  scrollToView();
};
const getMidNightMills = (plusDayFromToday) => {
  return DateTime.fromISO(
    DateTime.now().plus({ days: plusDayFromToday }).toISODate()
  ).toMillis();
};

const moreHandle = (programId) => {
  console.log("click more", programId);
};

const programClickHandle = async (programItem) => {
  if ((program.isPlus || programItem.isPlus) && !sessionStore.isAuthenticated) {
    useNeedLoginPopup().isUnAuthPopup();
    return true;
  }
  if (programItem.carType == "now") {
    const selectedChannel = props.channels.find(
      (c) =>
        c.externalChannelId ==
        decodeURI(currentPrograms.value.channelExternalId)
    );

    if (selectedChannel.number > 100) {
      // is a radio station
      if (!useNeedLoginPopup().isPlusNeedPopup(selectedChannel)) {
        useRadioStore().$patch((state) => {
          state.widgetStation = selectedChannel;
          state.showWidget = true;
        });
      }
      return;
    }

    if (selectedChannel.externalChannelId === "Majid Children Channel") {
      navigateTo({
        path: localePath(`/kids/majid-tv}`),
        query: {
          autoplay: route?.query?.autoplay,
          channel: channel.externalChannelId,
        },
      });
    } else {
      navigateTo({
        path: localePath(`/live/${selectedChannel.externalChannelId}`),
        query: {
          autoplay: true,
        },
      });
    }

    navigateTo({
      path: localePath(`/live/${selectedChannel.externalChannelId}`),
      query: {
        autoplay: true,
      },
    });
  }
};

onMounted(async () => {
  await getProgramList();
  currentPrograms.value = formattedProgramList.value?.[0];
  scrollToView();
});
const todayAndTomorrow = (i) => {
  if (i - 1 === 0) {
    return t("Today");
  } else if (i - 1 === 1) {
    return t("Tomorrow");
  } else {
    const now = DateTime.now().plus({ days: i - 1 });
    return `${now.toFormat("EEE")} ${now.toFormat("dd MMM")}`;
  }
};

/**
 * - the live tag logic in EPG is wrong, we should show it for
 * the current program only when its ef:telecast = live.
 * otherwise only highlight, no live tag.
 */
const showLiveTag = (programItem) => {
  const isNow = programItem.carType == "now";
  const isTelecastLive =
    programItem.extrafields
      .find((e) => e.name == "telecast_type")
      ?.value?.toUpperCase() == "LIVE";

  return isNow && isTelecastLive;
};
</script>
<style lang="scss" scoped>
.channel-list {
  height: auto; //TODO: wait for ui design
}
.content-width {
  width: calc(100vw - 16px);
}
.inner-width {
  width: calc(100vw - 32px);
}
.fixed-container {
  position: fixed;
  inset-inline-start: 0px;
}
.btn-selected {
  border: 2px solid
    var(--stroke-stroke-state-input-activated, rgba(255, 255, 255, 0.8));
}
.schedule-program {
  &-detail {
    padding: 8px 16px;
    &-pass {
      background: var(--greys-white-10, rgba(255, 255, 255, 0.1));
      color: var(--greys-white-40, rgba(255, 255, 255, 0.4));
    }
    &-now {
      background: var(--greys-white-30, rgba(255, 255, 255, 0.3));
      cursor: pointer;
    }
    &-later {
      background: var(--greys-white-10, rgba(255, 255, 255, 0.1));
      cursor: pointer;
    }
  }
}
</style>
